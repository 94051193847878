@tailwind base;
@tailwind components;
@tailwind utilities;

.bubble-arrow {
  content: "";
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(100% - 0.5rem);
  transform: rotate(45deg);
  border-bottom: inherit;
  border-right: inherit;
  @apply bg-sporticon-bg w-4 h-4;
}
